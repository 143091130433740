import React from "react";
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../request/RequestError";
import RequestLoading from "../../request/RequestLoading";


const DependentesPerfil = props => {

    const { data, error, isLoading } = useLoadData('/dependentes');
    const pessoas = data?.pessoas || [];

    if (error) return <RequestError  error={error} />;
    if (isLoading) return <RequestLoading />;
    return (
        <>
            {
                pessoas?.map(pessoa => {

                    const badge = <span className={`badge ${pessoa.situacao_id !== 1 ? 'bg-danger' : 'bg-success'}`}>{pessoa.sit}</span>

                    return (
                        <div key={pessoa.id} className="border-bottom pb-3 mb-3">
                            <span>{pessoa.nome} - <small>{pessoa.matricula} - {badge}</small></span> <br />
                            <small className="text-muted">Data Nascimento - {pessoa.nascimento}</small> <br />
                            <small className="text-muted">CPF - {pessoa.cpf}</small> <br />
                        </div>
                    );
                })
            }
            <small className="text-center d-block">
                <i>Para realizar quaisquer alterações no cadastro de dependentes, favor entrar em contato com o nosso serviço de atendimento.</i>
            </small>
        </>
    );
}

export default DependentesPerfil;