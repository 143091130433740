import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import RowDados from "../../../utils/RowDados";
import { mutate } from 'swr';
import FormPadrao from "../../../utils/form";

const InformacoesPerfil = props => {

    const context = useOutletContext();
    const [pessoa, setPessoa] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (context?.pessoa) setPessoa({ ...context.pessoa });
    }, [context]);

    useEffect(() => {
        if (pessoa.nascimento) pessoa.nascimento = pessoa.nascimento.split('/').reverse().join('-');
    }, [pessoa]);

    function onSubmitSuccess() {
        mutate('/meu-perfil');
        handleClose();
    }


    const formEdit = {
        action: '/meu-perfil',
        method: 'PUT',
        data: pessoa,
        onSubmitSuccess,
        fields: [
            { name: 'nome', type: 'text', label: 'Nome', col: '12', required: 'Nome não informado!', disabled: true },
            { name: 'apelido', type: 'text', label: 'Apelido', col: '12', autoFocus: true },
            { name: 'nascimento', type: 'date', label: 'Data de Nascimento', col: '12', disabled: (pessoa.nascimento ? true : false), required: 'Data de Nascimento não informado!' },
            { name: 'cpf', type: 'text', label: 'CPF', col: '12', required: 'CPF não informado!', disabled: true },
            { name: 'rg', type: 'text', label: 'RG', col: '12' },
            { name: 'rg_em', type: 'text', label: 'RG - Emissor', col: '12' },
        ],
        submits: [
            { label: 'Atualizar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <RowDados label="Nome" value={pessoa.nome} onClick={handleShow} />
            <hr />
            <RowDados label="Apelido" value={pessoa.apelido} onClick={handleShow} />
            <hr />
            <RowDados label="Data Nascimento" value={pessoa.nascimento} onClick={handleShow} />
            <hr />
            <RowDados label="CPF" value={pessoa.cpf} />
            <hr />
            <RowDados label="RG" value={`${pessoa.rg || ''} - ${pessoa.rg_em || ''}`} onClick={handleShow} />


            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{pessoa.nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default InformacoesPerfil;