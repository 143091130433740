import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import ESV from "../../../esv";

const FooterApp = props => {

    const [empresa, setEmpresa] = useState({});


    useEffect(() => {
        if (!props) return false;
        const pessoa = props.pessoa;
        const empresa = props.empresa;

        if (!pessoa?.icon) pessoa.icon = '/default-avatar.png';
        if (pessoa?.nome) pessoa.nome = pessoa.nome.split(' ').shift();


        setEmpresa({ ...empresa });
    }, [props]);

    return (
        <>
            <div className="footer py-lg-3 py-2 bg-light d-none d-lg-block">
                <Container className="">
                    <div className="text-center py-1">
                        <h4 className="m-0 empresa-nome">{empresa?.nome_fantasia}</h4>
                        <small className="empresa-nome-social d-lg-block d-none">{empresa?.razao_social}</small>
                    </div>
                    <div className="text-end pt-lg-1">
                        <ESV.Develop />
                    </div>
                </Container>
            </div>
        </>
    );
}

export default FooterApp;