import React from "react";
import StepInput from "./StepInput";

const StepCadastro = props => {

    const fields = [
        { name: 'name', label: 'Nome', type: 'text', col: '12', required: 'Nome não informado!' },
        { name: 'cpf', label: 'CPF', type: 'text', col: '6', mask: '999.999.999-99', required: 'CPF não informado!' },
        { name: 'nascimento', label: 'Data Nascimento', type: 'date', col: '6', required: 'Data Nascimento não informado!' },
        { name: 'email', label: 'E-mail', type: 'email', col: '6', required: 'E-mail não informado!' },
        { name: 'tel', label: 'Telefone', type: 'text', mask: '(99) 99999-9999', col: '6', required: 'Telefone não informado!' },
    ];

    return <>
        <StepInput {...props} fields={fields} />
    </>
}

export default StepCadastro;