import React, { useEffect, useState } from "react";
import useLoadData from "../../../data/swr/useLoadData";
import RequestError from "../../request/RequestError";
import RequestLoading from "../../request/RequestLoading";
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Link, useOutletContext } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

const WebLinkPlanos = props => {

    const context = useOutletContext();
    const { data, error, isLoading } = useLoadData('/web-link/planos');
    const [planos, setPlanos] = useState([]);
    const [empresa, setEmpresa] = useState({});

    useEffect(() => {
        if (data?.planos) setPlanos([...data.planos]);
        if (context?.empresa) setEmpresa({ ...context.empresa });
    }, [data, context]);

    if (error) return <RequestError  error={error} />;
    if (isLoading) return <RequestLoading />;
    return (
        <>
            <Row>
                {
                    planos?.map(plano => {
                        return (
                            <Col lg="4" md="6" key={plano?.id} className="mb-4">
                                <Link to={`/novo-cadastro/plano/${btoa(`plano-${plano?.recorrencia}-${plano?.id}`)}`} className="h-100">
                                    <Card className="h-100">
                                        <Card.Img variant="top" src={plano?.card_frente ? plano.card_frente : empresa?.LAYOUT?.fileLogo} />
                                        <Card.Body className="text-center d-flex flex-column justify-content-around flex-wrap align-items-center">
                                            <Card.Title>
                                                Plano
                                                <h2 className="mt-0 mb-3" style={{ color: "var(--cor-primaria)" }}>{plano?.nome}</h2>
                                                <h3>
                                                    <small className="fs-6">R$</small>
                                                    <span style={{ color: "var(--cor-primaria)" }}>{plano.valor}</span>
                                                    <small className="fs-6">/{plano.recorrencia}</small>
                                                </h3>
                                            </Card.Title>
                                            <Card.Text>
                                                <small>{plano.desc}</small>
                                            </Card.Text>
                                            <div className="text-center">
                                                <Button className="btn btn-primary">Contratar Plano {plano?.recorrencia} <Icon.ArrowRight /> </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    );
}


export default WebLinkPlanos;