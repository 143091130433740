import * as yup from "yup";
import Inputmask from 'inputmask';
import { useForm } from "react-hook-form";
import * as Icon from 'react-bootstrap-icons';
import React, { useEffect, useState } from "react";
// import { useOutletContext } from "react-router-dom";
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { yupResolver } from "@hookform/resolvers/yup";
import StepCadastro from "./steps/Cadastro";
import StepEndereco from "./steps/Endereco";
import StepResumo from "./steps/Resumo";
import StepPagamento from "./steps/Pagamento";

const WebLinkCadastro = props => {

    // const context = useOutletContext();
    // const [empresa, setEmpresa] = useState({});
    const [activeStep, setActiveStep] = useState(0);


    const steps = [
        { name: 'Cadastro', label: <Icon.PersonFillAdd /> },
        { name: 'Endereço', label: <Icon.GeoAltFill /> },
        { name: 'Resumo', label: <Icon.FileTextFill /> },
        { name: 'Pagamento', label: <Icon.WalletFill /> }
    ];

    const validacaoStep = [
        yup.object({
            name: yup.string().required(),
            cpf: yup.string().required(),
            nascimento: yup.string().required(),
            email: yup.string().required(),
            tel: yup.string().required()
        }),
        yup.object({
            cep: yup.string().required(),
            rua: yup.string().required(),
            numero: yup.string().required(),
            bairro: yup.string().required(),
            cidade: yup.string().required(),
            uf: yup.string().required(),
        }),
        yup.object({}),
        yup.object({}),
    ];
    const configForm = useForm({
        shouldUnregister: false,
        resolver: yupResolver(validacaoStep[activeStep]),
        mode: "onChange"
    });

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <StepCadastro {...configForm} />;
            case 1:
                return <StepEndereco {...configForm} />;
            case 2:
                return <StepResumo {...configForm} />;
            case 3:
                return <StepPagamento {...configForm} />;
            default:
                return <StepCadastro {...configForm} />;
        }
    }

    async function setNextStep() {
        if ((steps.length - 1) === activeStep) {
            alert('Submit');
        } else {
            const isAproved = await configForm.trigger();
            if (isAproved) setActiveStep(activeStep + 1);
        }
    }

    function setPrevStep() {
        if (activeStep > 0)
            setActiveStep(activeStep - 1);
    }

    async function onSubmit(formData) {

    }

    // useEffect(() => {
    //     if (context?.empresa) setEmpresa({ ...context.empresa });
    // }, [context]);

    useEffect(() => {
        document.querySelectorAll('[data-inputmask]').forEach((element, index, array) => {
            Inputmask().mask(element);
        })
    }, [activeStep]);


    return (
        <>
            <Container>
                <Card>
                    <Card.Header>
                        <Row className="justify-content-around">
                            {steps.map((step, i) => <Col className="col-auto fs-3" key={step.name}><span className={activeStep === i ? "text-primary" : "text-muted"}>{step.label}</span></Col>)}
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <form onSubmit={configForm.handleSubmit(onSubmit)}>
                            <Row>
                                {getStepContent(activeStep)}
                            </Row>
                        </form>
                    </Card.Body>
                    <Card.Footer className="text-center">
                        <Button variant="secondary" size="sm" className="me-3" disabled={activeStep === 0 ? true : false} onClick={setPrevStep}>
                            Voltar
                        </Button>
                        <Button variant="primary" size="sm" onClick={setNextStep}>
                            Prosseguir
                        </Button>
                    </Card.Footer>
                </Card>
            </Container>
        </>
    );
}


export default WebLinkCadastro;