import React, { useContext, useEffect } from 'react';
import BodyApp from './bodyApp';
import FooterApp from './footerApp';
import HeaderApp from './headerApp';
import useLoadData from '../../data/swr/useLoadData';
import RequestLoading from '../request/RequestLoading';
import RequestError from '../request/RequestError';
import PrivateRoute from '../../layouts/PrivateRoute';
import AppContext from '../../context/Context';

const LayoutApp = () => {

    const { contextValue, setContextValue } = useContext(AppContext);
    const { data, error, isLoading } = useLoadData('/');


    useEffect(() => {
        const allowed = data?.allowed || [];
        if (allowed) setContextValue({ ...contextValue, allowed });
        // eslint-disable-next-line
    }, [data]);

    if (error) return <RequestError error={error} />;
    if (isLoading) return <RequestLoading />;
    return (
        <>
            <HeaderApp {...data} />
            <BodyApp {...data}>
                <PrivateRoute {...data} />
            </BodyApp>
            <FooterApp {...data} />
            <style dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --cor-primaria: ${data?.modulo?.cor_primaria};
                    --cor-secundaria: ${data?.modulo?.cor_secundaria};
                    --bs-primary: ${data?.modulo?.cor_primaria};
                    --bs-secondary: ${data?.modulo?.cor_secundaria};
                }
              `,
            }}></style>
        </>
    );
};

export default LayoutApp;
