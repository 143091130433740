import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import RowDados from "../../../utils/RowDados";
import FormPadrao from "../../../utils/form";
import { mutate } from 'swr';

const ComunicacaoPerfil = props => {

    const context = useOutletContext();
    const [pessoa, setPessoa] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (context?.pessoa) setPessoa({ ...context.pessoa });
    }, [context]);

    function onSubmitSuccess() {
        mutate('/meu-perfil');
        handleClose();
    }


    const formEdit = {
        action: '/meu-perfil',
        method: 'PUT',
        data: pessoa,
        onSubmitSuccess,
        fields: [
            { name: 'email', type: 'email', label: 'E-mail', col: '12', required: 'E-mail não informado!', disabled: true },
            { name: 'whats', type: 'text', label: 'Whatsapp', col: '12', required: 'Whatsapp não informado!', autoFocus: true, mask: "(99) 99999-9999" },
            { name: 'tel', type: 'text', label: 'Telefone', col: '12', mask: "(99) 99999-9999" },
            { name: 'facebook', type: 'text', label: 'Facebook', col: '12' },
            { name: 'linkedin', type: 'text', label: 'Linkedin', col: '12' },
            { name: 'instagram', type: 'text', label: 'Instagram', col: '12' },
        ],
        submits: [
            { label: 'Atualizar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <RowDados label="E-mail" value={pessoa.email} />
            <hr />
            <RowDados label="Whatsapp" value={pessoa.whats} onClick={handleShow} />
            <hr />
            <RowDados label="Telefone" value={pessoa.tel} onClick={handleShow} />
            <hr />
            <RowDados label="Facebook" value={pessoa.facebook} onClick={handleShow} />
            <hr />
            <RowDados label="Linkedin" value={pessoa.linkedin} onClick={handleShow} />
            <hr />
            <RowDados label="Instagram" value={pessoa.instagram} onClick={handleShow} />


            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Alterando Comunicação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ComunicacaoPerfil;