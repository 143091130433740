import React, { useContext, useEffect, useState } from "react";
import "./styleLogin.css";
import { Outlet } from 'react-router-dom';
import AppContext from "../../context/Context";
import { Col, Row, Card, Container } from 'react-bootstrap';

const LayoutLogin = props => {

    const { contextValue } = useContext(AppContext);
    // const [empresa, setEmpresa] = useState({});
    const [modulo, setModulo] = useState({});
    const [corPrimaria, setCorPrimaria] = useState('#000000');
    const [corSecundaria, setCorSecundaria] = useState('#000000');


    useEffect(() => {
        // if (contextValue.empresa) setEmpresa({ ...contextValue.empresa });
        if (contextValue.modulo) setModulo({ ...contextValue.modulo });
    }, [contextValue]);

    useEffect(() => {
        if (modulo) setCorPrimaria(modulo.cor_primaria);
        if (modulo) setCorSecundaria(modulo.cor_secundaria);
    }, [modulo]);

    return (
        <>
            {/* <div style={{"background": `linear-gradient(to right, ${corPrimaria} 10%, ${corSecundaria} 90%)` }}> */}
            <div style={{ "background": `radial-gradient(${corSecundaria}, ${corPrimaria})` }}>
                <Container>
                    <div style={{ "height": "100vh", "width": "100%" }}>
                        <Row className="h-100 justify-content-center align-items-center">
                            <Col lg="auto">
                                <Card className="card-login">
                                    <Card.Body className="p-4 p-lg-5 text-light">
                                        <div className="img-container text-center mb-4">
                                            <img src={modulo?.icon} className="img-fluid" alt={modulo?.title} style={{ "maxHeight": "70px", "width": "auto" }} />
                                            <h4 className="m-0"><b>{modulo?.title}</b></h4>
                                        </div>
                                        <Outlet />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div >
                </Container>
            </div>
        </>
    );
}


export default LayoutLogin;