import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './layouts/Layout';
import { ToastContainer } from 'react-toastify';
import 'bootstrap';
import AppContext from './context/Context';
import useLoadData from './data/swr/useLoadData';
import 'line-awesome/dist/line-awesome/css/line-awesome.css';
import 'line-awesome/dist/line-awesome/fonts/la-brands-400.svg';
import 'line-awesome/dist/line-awesome/fonts/la-regular-400.svg';
import 'line-awesome/dist/line-awesome/fonts/la-solid-900.svg';
import '@splidejs/react-splide/css';
import './appCustomStyle.css';
import RequestLoading from './components/request/RequestLoading';
import RequestError from './components/request/RequestError';
const App = () => {


    const { contextValue, setContextValue } = useContext(AppContext);
    const { data, error, isLoading } = useLoadData('/data');

    useEffect(() => {
        if (typeof (data) === "object") setContextValue({ ...contextValue, ...data });
        // eslint-disable-next-line
    }, [data]);

    if (error) return <RequestError error={error} />;
    if (isLoading) return <RequestLoading />;
    return (
        <>
            <Router basename={process.env.PUBLIC_URL}>
                <Layout />
            </Router>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
};

export default App;
