import React, { useEffect, useState } from "react";
import { useOutletContext, useLocation } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";


const HomePerfil = props => {

    const context = useOutletContext();
    const location = useLocation();
    const [menus, setMenus] = useState([]);
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        if (context?.menu) setMenus([...context.menu]);
    }, [context]);

    useEffect(() => {
        const path = location.pathname;
        const menusShow = menus?.filter(me => path === me.url)?.pop();
        setMenu(menusShow);
    }, [menus, location]);



    return (
        <>
            {
                menu?.sub_menu?.map(m => {
                    if (m.show) return <div key={m.id} className="mb-4">
                        <Link to={m.url} className="d-block fw-normal" style={{ color: 'inherit' }}>
                            <Row className="align-items-center">
                                <Col className="col-auto">
                                    <div className="rounded-circle border border-1 d-flex align-items-center justify-content-center fs-3" style={{ width: '50px', height: '50px' }}>
                                        <i className={`las la-${m.icon} text-primary`}></i>
                                    </div>
                                </Col>
                                <Col>
                                    <Row className="justify-content-between align-items-center">
                                        <Col className="col-auto">
                                            <span className="d-block">{m.label}</span>
                                            <small className="d-block">{m.slabel}</small>
                                        </Col>
                                        <Col className="col-auto">
                                            <i className="text-muted las la-angle-right"></i>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Link>
                    </div>
                    return true;
                })
            }
        </>
    );
}

export default HomePerfil;