import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../data/axios/api";
import ShowError from "../../components/request/ShowError";


const LoginNovaSenha = props => {

    const { register, formState: { errors }, control: { _fields }, handleSubmit, setError } = useForm();
    const [disabled, setDisabled] = useState(false);
    const navegate = useNavigate();
    let [searchParams] = useSearchParams();

    async function onSubmit(formData) {
        try {

            formData.token = searchParams.get("token");

            setDisabled(true);
            await api.post('/portalweb/nova-senha', { ...formData });
            setDisabled(false);
            ShowError('Acesso redefinido com sucesso!', 'success');
            return navegate('/');

        } catch (error) {
            setDisabled(false);
            if (typeof (props.onSubmitError) === "function") props.onSubmitError();
            if (error?.response?.data?.error?.name) {
                var ok = Object.keys(_fields).filter(e => e === error?.response?.data?.error?.name);
                console.log(ok.length);
                if (ok.length) return setError(error?.response?.data?.error?.name, { type: 'requestError', message: error?.response?.data?.error?.error });
            }
            if (error?.code === "ERR_NETWORK")
                return ShowError("Você está off-line!");
            return ShowError(error);
        }
    }

    return (
        <div className="container-form">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label mb-0">Nova Senha</label>
                    <input type="password" placeholder="Senha" id="password" className="form-control" {...register("password", { required: true })} disabled={disabled} />
                    {errors.password?.type === "required" && <small className="text-danger">Informe sua Senha</small>}
                    {errors?.password?.type === "requestError" && <small className="text-danger">{errors?.password?.message}</small>}
                </div>
                <div className="mb-3">
                    <label htmlFor="password1" className="form-label mb-0">Confirme a Senha</label>
                    <input type="password" placeholder="Confirme a Senha" id="password1" className="form-control" {...register("password1", { required: true })} disabled={disabled} />
                    {errors?.password1?.type === "required" && <small className="text-danger">Informe sua Senha</small>}
                    {errors?.password1?.type === "requestError" && <small className="text-danger">{errors?.password1?.message}</small>}
                </div>
                <div className="row justify-content-between mb-3">
                    <div className="col-auto">
                        <Link className="fs--1 text-light p-lg-0 py-1 d-block" to={"/login"}>
                            Fazer Login
                        </Link>
                    </div>
                    <div className="col-auto">
                        <Link className="fs--1 text-light p-lg-0 py-1 d-block" to={"/redefinir-senha"}>
                            Solicitar novamente?
                        </Link>
                    </div>
                </div>
                <div className="d-grid">
                    <button type="submit" disabled={disabled} className="btn btn-primary">
                        {disabled ?
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </> : 'Salvar'
                        }
                    </button>
                </div>
            </form>
        </div>
    );
}


export default LoginNovaSenha;