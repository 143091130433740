import React from "react";
// import useLoadData from "../../../data/swr/useLoadData";
// import RequestError from "../../request/RequestError";
// import RequestLoading from "../../request/RequestLoading";


const PagamentosPerfil = props => {

    // const { data, error, isLoading } = useLoadData('/pagamentos');
    // const pagamentos = data?.pagamentos || [];

    // if (error) return <RequestError />;
    // if (isLoading) return <RequestLoading />;

    return (
        <>
            <div className="p-4 text-center">
                Em breve!
            </div>
        </>
    );
}


export default PagamentosPerfil;