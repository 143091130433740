import React, { useState } from "react";
import { Col } from 'react-bootstrap';
import { v4 as hash } from 'uuid';
import axios from 'axios';


const StepInput = props => {
    const { register, formState: { errors }, reset } = props;
    const fields = props.fields;
    const [disabled, setDisabled] = useState(false);


    // BUSCANDO OS DADOS DO CEP
    register('cep', {
        onChange: async (e) => {

            try {
                var cep = e.target.value.replace(/[^0-9]/g, '');
                if (cep?.length !== 8) return false;
                setDisabled(true);
                var cep_name = [];
                const cep_info = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
                if (cep_info?.data) cep_name = Object.keys(cep_info.data);

                const data_cep = {};
                cep_name.map(name => {
                    switch (name) {
                        case 'bairro': data_cep[name] = cep_info.data[name]; break;
                        case 'cep': data_cep[name] = cep_info.data[name]; break;
                        case 'logradouro': data_cep['rua'] = cep_info.data[name]; break;
                        case 'uf': data_cep[name] = cep_info.data[name]; break;
                        case 'localidade': data_cep.cidade = cep_info.data[name]; break;
                        // case 'ibge': data_cep.ibge = cep_info.data[name]; break;
                        default: break;
                    }
                    return true;
                });

                reset({ ...data_cep });
                setDisabled(false);
                return true;
            } catch (error) {
                setDisabled(false);
                console.log(error);
                return false;
            }
        }
    });

    return <>
        {
            fields?.map((f, i) => {

                const input_id = hash();
                const label = f?.label;
                const name = f?.name;
                const type = f?.type || 'text';
                const col = f?.col || '12';
                const mask = f?.mask ? "'mask':'" + f?.mask + "'" : '';


                return (
                    <Col key={i} className={`col-lg-${col} col-12`}>
                        <div className="mb-2 form-floating">
                            {type === "textarea" ? (
                                <textarea className="form-control" disabled={disabled} data-inputmask={mask} id={input_id} placeholder={label} {...register(name)} style={{ height: "100px" }}></textarea>
                            ) : (
                                <input type={type} className="form-control" disabled={disabled} data-inputmask={mask} id={input_id} placeholder={label} {...register(name)} />
                            )}
                            <label htmlFor={input_id}>
                                {label}
                                <small className="ms-1 text-muted">{f?.required ? '(Obrigatório)' : '(Opcional)'}</small>
                            </label>
                            {errors[name]?.type === "required" && <small className="text-danger">{f?.required}</small>}
                            {errors[name]?.type === "requestError" && <small className="text-danger">{errors[name]?.message}</small>}
                        </div>
                    </Col>
                )
            })
        }
    </>
}

export default StepInput;