import React from "react";
import { Card, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Splide } from '@splidejs/react-splide';
import './style.css';


// Componente que retorna tudo dentro de card
const CmpCard = props => {
    const config = props?.config || {};
    return (
        <div className={`cmp-card ${config?.class ? config?.class : ''} mb-${config?.mb ? config?.mb : ''}`} name={props?.name ? props?.name : ""}>
            <Card>
                <Card.Body>
                    <Row>
                        {props.children}
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export const CmpContainer = props => {
    const config = props?.config || {};
    return (
        <div className={`cmp-container ${config?.class ? config?.class : ''} mb-${config?.mb ? config?.mb : ''}`} name={props?.name ? props?.name : ""}>
            <div className="container">
                <div className="row">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export const CmpSlide = props => {
    const config = props?.config || {};
    return (
        <div className={`cmp-slide ${config?.class ? config?.class : ''} mb-${config?.mb ? config?.mb : ''}`} name={props?.name ? props?.name : ""}>
            <Splide
                aria-label={props.name}
                options={{
                    arrows: false,
                    type: config.viewType || 'loop',
                    autoplay: config.autoPlay || true,
                    perPage: config.lgPerPage || 1,
                    breakpoints: {
                        780: {
                            perPage: config.smPerPage || 2,
                        },
                    }
                }}>
                {props.children}
            </Splide>
        </div>
    );
}


















const Imagem = props => {
    const imagem = props?.ctt || {};
    const config = imagem?.config || {};
    const img = <img src={config.imagem} alt={config.title} className={`${config.display ? config.display : ''} img-fluid`} />;
    const legenda = <div className="legenda text-body fw-normal">{config.legenda}</div>;
    const title = <h4 className="title mb-2 mt-0 fs-5">{config.title}</h4>;

    return (
        <>
            <div className={`type-imagem img-container ${config.class ? config.class : ''} `} name={imagem.name}>
                {
                    config.link ? (
                        <Link to={config.link} target={config.link.indexOf('://') > -1 ? '_blank' : '_top'}>
                            {config.imagem && img}
                            {(config.title || config.legenda) && <div className="py-2 px-1">
                                {title}
                                {legenda}
                            </div>}
                        </Link>
                    ) : (
                        <>
                            {config.imagem && img}
                            {(config.title || config.legenda) && <div className="py-2 px-1">
                                {title}
                                {legenda}
                            </div>}
                        </>
                    )
                }
            </div>
        </>
    );
}

const Linkc = props => {
    const link = props?.ctt || {};
    const config = link?.config || {};

    return (
        <>
            <div className={`type-link link-container ${config.class ? config.class : ''} `} name={link.name}>
                <Link to={config.link} target={config.link.indexOf('://') > -1 ? '_blank' : '_top'}>
                    {config.title}
                </Link>
            </div>
        </>
    );
}

const Title = props => {
    const title = props?.ctt || {};
    const config = title?.config || {};

    const conteudo = <h2 className="title fw-bold">
        {config.title}
        <br />
        <small className="sub-title fw-normal">{config.subTitle}</small>
    </h2>

    return (
        <>
            <div className={`type-title title-container ${config.class ? config.class : ''} `} name={title.name}>
                {
                    config.link ? (
                        <Link to={config.link} target={config.link.indexOf('://') > -1 ? '_blank' : '_top'}>
                            {conteudo}
                        </Link>
                    ) : (
                        <>
                            {conteudo}
                        </>
                    )
                }
            </div>
        </>
    );
}

const Texto = props => {
    const texto = props?.ctt || {};
    const config = texto?.config || {};
    const conteudo = <div className="texto">{config.texto}</div>

    return (
        <>
            <div className={`type-texto texto-container ${config.class ? config.class : ''} `} name={texto.name}>
                {
                    config.link ? (
                        <Link to={config.link} target={config.link.indexOf('://') > -1 ? '_blank' : '_top'}>
                            {conteudo}
                        </Link>
                    ) : (
                        <>
                            {conteudo}
                        </>
                    )
                }
            </div>
        </>
    );
}




CmpCard.Imagem = Imagem;
CmpCard.Link = Linkc;
CmpCard.Title = Title;
CmpCard.Texto = Texto;

CmpSlide.Imagem = Imagem;
CmpSlide.Link = Linkc;
CmpSlide.Title = Title;
CmpSlide.Texto = Texto;

CmpContainer.Imagem = Imagem;
CmpContainer.Link = Linkc;
CmpContainer.Title = Title;
CmpContainer.Texto = Texto;

export default CmpCard;