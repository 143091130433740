import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../data/axios/api";
import ShowError from "../../components/request/ShowError";
import jwt_decode from 'jwt-decode';
import AppContext from "../../context/Context";


const Login = props => {

    const { register, formState: { errors }, control: { _fields }, handleSubmit, setError } = useForm();
    const { contextValue, setContextValue } = useContext(AppContext);
    const [disabled, setDisabled] = useState(false);
    const navegate = useNavigate();


    async function onSubmit(formData) {
        try {
            setDisabled(true);
            const { data } = await api.post('/login', { ...formData });
            if (!!data?.token) {
                const payload = jwt_decode(data?.token);
                localStorage.setItem(process.env.REACT_APP_NAMETOKEN, data.token);
                setContextValue({ ...contextValue, ...payload, token: data?.token, isAuthenticated: true });
                return navegate('/');
            }
            setDisabled(false);

        } catch (error) {
            setDisabled(false);
            if (typeof (props.onSubmitError) === "function") props.onSubmitError();
            if (error?.response?.data?.error?.name) {
                var ok = Object.keys(_fields).filter(e => e === error?.response?.data?.error?.name);
                if (ok.length) return setError(error?.response?.data?.error?.name, { type: 'requestError', message: error?.response?.data?.error?.error });
            }
            if (error?.code === "ERR_NETWORK")
                return ShowError("Você está off-line!");
            return ShowError(error);
        }
    }

    return (
        <div className="container-form">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label htmlFor="email" id="email" className="form-label mb-0">E-mail ou CPF</label>
                    <input type="text" placeholder="E-mail ou CPF" className="form-control" {...register("email", { required: true })} disabled={disabled} />
                    {errors.email && <small className="text-danger">E-mail/CPF não informado ou inválido</small>}
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label mb-0">Senha</label>
                    <input type="password" placeholder="Senha" id="password" className="form-control" {...register("password", { required: true })} disabled={disabled} />
                    {errors.password?.type === "required" && <small className="text-danger">Informe sua Senha</small>}
                    {errors?.password?.type === "requestError" && <small className="text-danger">{errors?.password?.message}</small>}
                </div>
                <div className="row justify-content-between mb-3">
                    <div className="col-auto">
                        <Link className="fs--1 text-light p-lg-0 py-1 d-block" to={{ pathname: "/primeiro-acesso" }}>
                            Primeiro acesso?
                        </Link>
                    </div>
                    <div className="col-auto">
                        <Link className="fs--1 text-light p-lg-0 py-1 d-block" to={{ pathname: "/redefinir-senha" }}>
                            Esqueceu sua senha?
                        </Link>
                    </div>
                </div>
                <div className="d-grid">
                    <button type="submit" disabled={disabled} className="btn btn-primary">
                        {disabled ?
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </> : 'Entrar'
                        }
                    </button>
                </div>
            </form>
        </div>
    );
}


export default Login;