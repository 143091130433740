import React from "react";
import StepInput from "./StepInput";

const StepEndereco = props => {
    const fields = [
        { name: 'cep', label: 'CEP', type: 'text', col: '3', mask: '99999-999', required: 'CEP não informado!' },
        { name: 'rua', label: 'Rua', type: 'text', col: '7', required: 'Rua não informado!' },
        { name: 'numero', label: 'Número', type: 'text', col: '2', required: 'Número não informado!' },
        { name: 'bairro', label: 'Bairro', type: 'text', col: '4', required: 'Bairro não informado!' },
        { name: 'cidade', label: 'Cidade', type: 'text', col: '6', required: 'Cidade não informado!' },
        { name: 'uf', label: 'Estado', type: 'text', col: '2', required: 'Estado não informado!' },
        { name: 'complemento', label: 'Complemento', type: 'textarea', col: '12' },
    ];

    return <>
        <StepInput {...props} fields={fields} />
    </>
}

export default StepEndereco;