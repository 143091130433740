import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useLoadData from '../../data/swr/useLoadData';
import RequestLoading from '../request/RequestLoading';
import RequestError from '../request/RequestError';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import ESV from '../../esv';


const LayotuWebLink = props => {
    const { data, error, isLoading } = useLoadData('/web-link');
    const [empresa, setEmpresa] = useState({});

    useEffect(() => {
        if (data?.empresa) setEmpresa({ ...data.empresa });
    }, [data]);

    if (error) return <RequestError  error={error} />;
    if (isLoading) return <RequestLoading />;
    return (
        <>
            <div className="header py-3 border-bottom bg-light">
                <Container>
                    <Row className='justify-content-lg-between justify-content-center align-items-center'>
                        <Col className='col-auto mb-lg-0 mb-3'>
                            <Link to={'#'}>
                                <img src={empresa?.LAYOUT?.fileLogo} alt={empresa?.nome_fantasia} className='img-thumbnail- border-0' height='100' />
                            </Link>
                        </Col>
                        <Col className='col-auto text-lg-end text-center  mb-lg-0 mb-1'>
                            <h4 className='mb-1'>{empresa?.nome_fantasia}</h4>
                            <span>
                                <span>{empresa?.logradouro}</span>,&nbsp;
                                <span>{empresa?.numero}</span>,&nbsp;
                                <span>{empresa?.bairro}</span>,&nbsp;
                                <span>{empresa?.cidade}</span>&nbsp;-&nbsp;
                                <span>{empresa?.uf}</span>
                            </span>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="body py-5">
                <Container>
                    <Outlet context={{ ...data }} />
                </Container>
            </div>
            <div className="footer py-4 bg-light">
                <div className="text-center">
                    <h4 className='mt-0'>
                        {empresa?.nome_fantasia} <br />
                        <small className='fs-6 fw-normal'>{empresa?.razao_social}</small>
                    </h4>
                    <div className="endereco">
                        <span>
                            <span>{empresa?.logradouro}</span>,&nbsp;
                            <span>{empresa?.numero}</span>,&nbsp;
                            <span>{empresa?.bairro}</span>,&nbsp;
                            <span>{empresa?.cidade}</span>&nbsp;-&nbsp;
                            <span>{empresa?.uf}</span>
                        </span>
                    </div>
                    <span>CNPJ: <b>{empresa.cnpj}</b></span>
                    <div className="share py-2">
                        <Link to={empresa?.LINKS?.ig} className='text-dark text-decoration-none mx-2 fs-4' target='_blank'><Icon.Instagram /></Link>
                        <Link to={empresa?.LINKS?.fb} className='text-dark text-decoration-none mx-2 fs-4' target='_blank'><Icon.Facebook /></Link>
                        <Link to={empresa?.LINKS?.wpp} className='text-dark text-decoration-none mx-2 fs-4' target='_blank'><Icon.Whatsapp /></Link>
                        <Link to={empresa?.LINKS?.site} className='text-dark text-decoration-none mx-2 fs-4' target='_blank'><Icon.Globe /></Link>
                    </div>
                </div>
                <Container>
                    <div className='text-end px-4'>
                        <ESV.Develop />
                    </div>
                </Container>
            </div>
            <style dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --cor-primaria: ${data?.modulo?.cor_primaria};
                    --cor-secundaria: ${data?.modulo?.cor_secundaria};
                    --bs-primary: ${data?.modulo?.cor_primaria};
                    --bs-secondary: ${data?.modulo?.cor_secundaria};
                }
              `,
            }}></style>
        </>
    );
}

export default LayotuWebLink;