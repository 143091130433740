import React, { useState } from 'react';
import AppContext from './context/Context';
import jwt_decode from 'jwt-decode';

const Main = props => {

    const token = localStorage.getItem(process.env.REACT_APP_NAMETOKEN);
    const isAuthenticated = !!token;
    const payload = isAuthenticated ? jwt_decode(token) : {};
    const [contextValue, setContextValue] = useState({ ...payload, token, isAuthenticated });

    return (
        <AppContext.Provider value={{ contextValue, setContextValue }}>
            {props.children}
        </AppContext.Provider>
    );
};

export default Main;
