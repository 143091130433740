import React from "react";
import { Link } from "react-router-dom";
import './style.css';

const ESV = props => <div className="esv">{props.children}</div>;

export const Develop = props => {

    return (
        <Link to={'https://esvsolucoes.com.br'} target="_blank" className="esv-develop">
            Desenvolvido por <b>ESV Soluções</b>
        </Link>
    );
}

ESV.Develop = Develop;
export default ESV;