import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import RowDados from "../../../utils/RowDados";
import { mutate } from 'swr';
import FormPadrao from "../../../utils/form";

const EnderecoPerfil = props => {

    const context = useOutletContext();
    const [pessoa, setPessoa] = useState({});

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (context?.pessoa) setPessoa({ ...context.pessoa });
    }, [context]);

    function onSubmitSuccess() {
        mutate('/meu-perfil');
        handleClose();
    }


    const formEdit = {
        action: '/meu-perfil',
        method: 'PUT',
        data: pessoa,
        onSubmitSuccess,
        fields: [
            { name: 'cep', type: 'text', label: 'CEP', col: '12', mask: "99999-999", autoFocus: true },
            { name: 'logradouro', type: 'text', label: 'Rua', col: '12', required: 'Rua não informado!' },
            { name: 'bairro', type: 'text', label: 'Bairro', col: '12', required: 'Bairro não informado!' },
            { name: 'numero', type: 'text', label: 'Número', col: '12', required: 'Número não informado!' },
            { name: 'cidade', type: 'text', label: 'Cidade', col: '12', required: 'Cidade não informado!' },
            { name: 'uf', type: 'text', label: 'Estado', col: '12', required: 'Estado não informado!' },
            { name: 'complemento', type: 'textarea', label: 'Complemento', col: '12' },
        ],
        submits: [
            { label: 'Atualizar', class: "btn btn-primary" }
        ],
    };

    return (
        <>
            <RowDados label="CEP" value={pessoa.cep} onClick={handleShow} />
            <hr />
            <RowDados label="Rua" value={pessoa.logradouro} onClick={handleShow} />
            <hr />
            <RowDados label="Bairro" value={pessoa.bairro} onClick={handleShow} />
            <hr />
            <RowDados label="Número" value={pessoa.numero} onClick={handleShow} />
            <hr />
            <RowDados label="Cidade" value={pessoa.cidade} onClick={handleShow} />
            <hr />
            <RowDados label="Estado" value={pessoa.uf} onClick={handleShow} />
            <hr />
            <RowDados label="Complemento" value={pessoa.complemento} onClick={handleShow} />


            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Alterando Endereço</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPadrao {...formEdit} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EnderecoPerfil;