import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import { Card, Col, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import RequestLoading from "../request/RequestLoading";
import useLoadData from "../../data/swr/useLoadData";
import { Outlet, useOutletContext } from 'react-router-dom';
import RequestError from "../request/RequestError";
import AppContext from "../../context/Context";

const MinhaConta = props => {

    const { data, error, isLoading } = useLoadData('/meu-perfil');
    const pessoa = data?.pessoa || {};
    const context = useOutletContext();
    const { contextValue, setContextValue } = useContext(AppContext);

    function logout() {
        localStorage.clear();
        setContextValue({ ...contextValue, isAuthenticated: false });
    }

    if (error) return <RequestError  error={error} />;
    if (isLoading) return <RequestLoading />;
    return (
        <>
            <div className="container-perfil mt-4">
                <Card className="mb-lg-3 mb-2">
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col className="col-auto">
                                {
                                    pessoa.fileIcon ? <img src={pessoa.fileIcon} width="70" height="70" className="rounded-circle" alt={pessoa.nome} /> :
                                        <div className="rounded-circle border border-2 d-flex align-items-center justify-content-center fs-1" style={{ width: '70px', height: '70px' }}><i className="las la-user"></i></div>
                                }
                            </Col>
                            <Col className="col text-start">
                                <Row className="justify-content-between align-items-center">
                                    <Col className="col-auto">
                                        <Link to={'/meu-perfil'} style={{ color: 'inherit' }}>
                                            <h4 className="m-0 pessoa-nome">{pessoa.nome}</h4>
                                            <small className="d-block">{pessoa.email}</small>
                                            <Badge bg={pessoa.sit === 'Cancelado' ? 'danger' : 'primary'} className="fw-normal">{pessoa.sit}: {pessoa.matricula}</Badge>
                                        </Link>
                                    </Col>
                                    <Col className="col-auto">
                                        <div className="position-relative">
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mb-4">
                    <Card.Body className="p-4">
                        <Outlet context={{ ...context, ...data }} />
                    </Card.Body>
                    <div className="text-center p-3">
                        <Button className="btn btn-link text-body-- bg-transparent text-decoration-none p-0 text-secondary border-0" onClick={logout}>
                            <i className="las la-power-off"></i> Sair da Conta
                        </Button>
                    </div>
                </Card>
            </div>
        </>

    );
}


export default MinhaConta;
