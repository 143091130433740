import React from "react";
import CmpCard, { CmpContainer, CmpSlide } from "..";
import { Col } from 'react-bootstrap';
import { SplideSlide } from '@splidejs/react-splide';


// Apenas devolve os blocos
const Bloco = props => {
    return <>{props.children}</>
}

// Bloco do Estilo card
const BCard = props => {
    const bloco = props || {};
    const conteudos = props?.conteudos || [];
    const config = bloco?.config || {};
    return (
        <>
            <CmpCard {...bloco}>
                {
                    conteudos?.map(ctt => {
                        switch (ctt.type) {
                            case 'Link':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Link ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            case 'Imagem':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Imagem ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            case 'Título':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Title ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            case 'Texto':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Texto ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            default:
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Texto ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                        }
                    })
                }
            </CmpCard>
        </>
    );
}

// Bloco do Estilo container
const BContainer = props => {
    const bloco = props || {};
    const conteudos = props?.conteudos || [];
    const config = bloco?.config || {};
    return (
        <>
            <CmpContainer {...bloco}>
                {
                    conteudos?.map(ctt => {
                        switch (ctt.type) {
                            case 'Link':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Link ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            case 'Imagem':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Imagem ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            case 'Título':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Title ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            case 'Texto':
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Texto ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                            default:
                                return (
                                    <Col key={ctt.id} className={`col-${config.colSm ? config.colSm : ''} col-lg-${config.colLg ? config.colLg : ''}`}>
                                        <CmpCard.Texto ctt={ctt} bloco={bloco} />
                                    </Col>
                                )
                        }
                    })
                }
            </CmpContainer>
        </>
    );
}

// Bloco do estilo slide
const BSlide = props => {
    const bloco = props || {};
    const conteudos = props?.conteudos || [];
    return (
        <>
            <CmpSlide {...bloco}>
                {
                    conteudos?.map(ctt => {
                        switch (ctt.type) {
                            case 'Link':
                                return (
                                    <SplideSlide key={ctt.id} className="px-2">
                                        <CmpCard.Link ctt={ctt} bloco={bloco} />
                                    </SplideSlide>
                                )
                            case 'Imagem':
                                return (
                                    <SplideSlide key={ctt.id} className="px-2">
                                        <CmpCard.Imagem ctt={ctt} bloco={bloco} />
                                    </SplideSlide>
                                )
                            case 'Título':
                                return (
                                    <SplideSlide key={ctt.id} className="px-2">
                                        <CmpCard.Title ctt={ctt} bloco={bloco} />
                                    </SplideSlide>
                                )
                            case 'Texto':
                                return (

                                    <SplideSlide key={ctt.id} className="px-2">
                                        <CmpCard.Texto ctt={ctt} bloco={bloco} />
                                    </SplideSlide>
                                )
                            default:
                                return (
                                    <SplideSlide key={ctt.id} className="px-2">
                                        <CmpCard.Texto ctt={ctt} bloco={bloco} />
                                    </SplideSlide>
                                )
                        }
                    })
                }
            </CmpSlide>
        </>
    );
}














Bloco.Conteiner = BContainer;
Bloco.Slide = BSlide;
Bloco.Card = BCard;
export default Bloco;