import React from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';


const Erro401 = props => {

    const navigate = useNavigate();

    return (
        <div className="row flex-center min-vh-100- py-6 text-center mt-5">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
                <div className="card">
                    <div className="card-body p-4 p-sm-4">
                        <div className="fw-bold fs-1 text-300 fs-error text-danger">401</div>
                        <p className="lead mt-4 font-sans-serif fw-normal mx-auto">Você não tem permissão para acessar a página buscada.</p>
                        <hr />
                        <p>
                            Verifique suas permissões e se a página não foi removida. Se você acha que isso é um erro, entre em contato conosco.
                        </p>
                        <button className="btn btn-primary btn-sm mt-3" onClick={() => navigate(-1)}>
                            <Icon.ReplyAll /> Voltar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Erro401;