import React, { useEffect, useState } from "react";
import useLoadData from "../../data/swr/useLoadData";
import RequestLoading from "../request/RequestLoading";
import RequestError from "../request/RequestError";
import { useLocation } from 'react-router-dom';
import Bloco from "../cmp-html/bloco";
import { Card, Row, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const Home = props => {

    const [blocos, setBlocos] = useState([]);
    const location = useLocation();
    const { data, error, isLoading } = useLoadData(`/get-path?path=${location.pathname}`);

    useEffect(() => {
        const blocos = data?.blocos || [];
        setBlocos([...blocos]);
    }, [data]);

    if (error) return <RequestError error={error} />;
    if (isLoading) return <RequestLoading />;
    return (
        <>
            <div className="page my-3">
                {
                    blocos.map(bloc => {
                        switch (bloc.type) {
                            case 'Card':
                                return <Bloco.Card key={bloc.id} {...bloc} />
                            case 'Slide':
                                return <Bloco.Slide key={bloc.id} {...bloc} />
                            case 'Container':
                                return <Bloco.Conteiner key={bloc.id} {...bloc} />
                            default:
                                return <Bloco.Card key={bloc.id} {...bloc} />
                        }
                    })
                }
                {
                    !blocos?.length &&
                    <>
                        <Row className="justify-content-center">
                            <Col lg="6">
                                <Card>
                                    <Card.Body className="text-center p-5">
                                        <Icon.CupHot className="mb-3" style={{ fontSize: "90px" }} />
                                        <h4 className="mb-0">Sem Conteúdos</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                }
            </div>
        </>
    );
}


export default Home;
