import React from "react";
import { Container } from 'react-bootstrap';

const BodyApp = props => {

    return (
        <>
            <Container className="body-main">
                {props.children}
            </Container>
        </>
    );
}

export default BodyApp;