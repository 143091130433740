import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../components/home';
import MinhaConta from '../components/minha-conta';
import AppContext from '../context/Context';
import Login from './authentication/login';
import LayoutApp from '../components/app';
import LayoutLogin from './authentication/LayoutLogin';
import LoginHash from './authentication/loginHash';
import LoginNovaSenha from './authentication/LoginNovaSenha';
import HomePerfil from '../components/minha-conta/home';
import InformacoesPerfil from '../components/minha-conta/informacoes';
import EnderecoPerfil from '../components/minha-conta/enderecos';
import ComunicacaoPerfil from '../components/minha-conta/comunicacao';
import DependentesPerfil from '../components/minha-conta/dependentes';
import PagamentosPerfil from '../components/minha-conta/pagamentos';
import DadosContaPerfil from '../components/minha-conta/dados-conta';
import SegurancaPerfil from '../components/minha-conta/seguranca';
import LayotuWebLink from '../components/web-link';
import WebLinkPlanos from '../components/web-link/planos';
import WebLinkCadastro from '../components/web-link/cadastro';


const Layout = () => {

    const { contextValue } = useContext(AppContext);
    if (!contextValue?.isAuthenticated) {
        return (
            <>
                <Routes>

                    <Route element={<LayotuWebLink />}>
                        <Route exact path="/novo-cadastro" element={<WebLinkPlanos />} />
                        <Route exact path="/novo-cadastro/plano/:hash?" element={<WebLinkCadastro />} />
                    </Route>

                    <Route element={<LayoutLogin />}>
                        <Route path="/primeiro-acesso" element={<LoginHash />} />
                        <Route path="/redefinir-senha" element={<LoginHash />} />
                        <Route path="/nova-senha/:token?" element={<LoginNovaSenha />} />
                        <Route path="*" element={<Login />} />
                    </Route>
                </Routes>
            </>
        );
    }

    /* Start Layout do APP */
    return (
        <>
            <Routes>

                <Route element={<LayotuWebLink />}>
                    <Route exact path="/novo-cadastro" element={<WebLinkPlanos />} />
                    <Route exact path="/novo-cadastro/plano/:hash?" element={<WebLinkCadastro />} />
                </Route>

                <Route element={<LayoutApp />}>
                    <Route exact path="/" element={<Home />} />
                    <Route element={<MinhaConta />}>
                        <Route exact path="/meu-perfil" element={<HomePerfil />} />
                        <Route exact path="/meu-perfil/informacoes" element={<InformacoesPerfil />} />
                        <Route exact path="/meu-perfil/endereco" element={<EnderecoPerfil />} />
                        <Route exact path="/meu-perfil/comunicacao" element={<ComunicacaoPerfil />} />
                        <Route exact path="/meu-perfil/dependentes" element={<DependentesPerfil />} />
                        <Route exact path="/meu-perfil/pagamentos" element={<PagamentosPerfil />} />
                        <Route exact path="/meu-perfil/conta" element={<DadosContaPerfil />} />
                        <Route exact path="/meu-perfil/seguranca" element={<SegurancaPerfil />} />
                    </Route>
                    <Route path="*" element={<Home />} />
                </Route>

            </Routes>
        </>
    );
};

export default Layout;
