import React from "react";
import { Row, Col } from 'react-bootstrap';

const RowDados = props => {

    const label = props.label;
    const value = props.value;
    const click = props.onClick;


    return (
        <>
            <div className="container-informacoes">
                <Row className="justify-content-between align-items-center">
                    <Col>
                        <span>{label}</span> <br />
                        <small className="text-muted">{value || 'Não informado'}</small>
                    </Col>
                    <Col className="col-auto">
                        <button className="btn p-0 m-0 text-decoration-none text-primary border-0" onClick={click} disabled={click ? false : true}><small>Alterar</small></button>
                    </Col>
                </Row>
            </div>
        </>
    );
}


export default RowDados;