import { toast } from 'react-toastify';

const ShowError = (error, type = 'error') => {
    if (error?.response?.data?.error?.error) {
        error = error?.response?.data?.error?.error;
    } else if (error?.response?.data?.error) {
        error = error?.response?.data?.error;
    }
    if (typeof (error) !== "string" && error?.response?.data) return toast.error(error.response.data);
    if (type === 'error') return toast.error(error);
    return toast.success(error);
}

export default ShowError;