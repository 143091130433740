import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../data/axios/api";
import ShowError from "../../components/request/ShowError";


const LoginHash = props => {

    const { register, formState: { errors }, control: { _fields }, handleSubmit, setError } = useForm();
    const [disabled, setDisabled] = useState(false);
    const navegate = useNavigate();


    async function onSubmit(formData) {
        try {
            setDisabled(true);
            const { data } = await api.post('/portalweb/primeiro-acesso', { ...formData });
            if (data?.msg) {
                ShowError(data?.msg, 'success');
                return navegate('/');
            }

            setDisabled(false);
        } catch (error) {
            setDisabled(false);
            if (typeof (props.onSubmitError) === "function") props.onSubmitError();
            if (error?.response?.data?.error?.name) {
                var ok = Object.keys(_fields).filter(e => e === error?.response?.data?.error?.name);
                if (ok.length) return setError(error?.response?.data?.error?.name, { type: 'requestError', message: error?.response?.data?.error?.error });
            }
            if (error?.code === "ERR_NETWORK")
                return ShowError("Você está off-line!");
            return ShowError(error);
        }
    }

    return (
        <div className="container-form">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label htmlFor="email" id="email" className="form-label mb-0">E-mail</label>
                    <input type="text" placeholder="E-mail" className="form-control" {...register("email", { required: true })} disabled={disabled} />
                    {errors.email && <small className="text-danger">E-mail não informado ou inválido</small>}
                </div>
                <div className="mb-3">
                    <label htmlFor="cpf" id="cpf" className="form-label mb-0">CPF</label>
                    <input type="text" placeholder="CPF" className="form-control" {...register('cpf')} disabled={disabled} />
                    {errors.cpf && <small className="text-danger">CPF não informado ou inválido</small>}
                </div>
                <div className="row justify-content-between mb-3">
                    <div className="col-auto">
                        <Link className="fs--1 text-light p-lg-0 py-1 d-block" to={"/login"}>
                            Fazer Login
                        </Link>
                    </div>
                </div>
                <div className="d-grid">
                    <button type="submit" disabled={disabled} className="btn btn-primary">
                        {disabled ?
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </> : 'Enviar'
                        }
                    </button>
                </div>
            </form>
        </div>
    );
}


export default LoginHash;